import styled from 'styled-components';

const RegularFont = 'Rubik-Regular';

const Layout = styled.div`
  width: 100%;
  padding: 42px 44px 0 33px;
  font-family: ${RegularFont};
  margin-bottom: 77px;
  float: left;

  @media (max-width: 500px) {
    padding: 42px 15px 0;
  }
`;

const PageTitle = styled.h1`
  font-size: 24px;
  letter-spacing: 1px;
  color: rgb(51,51,51);
  text-transform: capitalize;
  margin-bottom: 21px;
`;

const ContentContainer = styled.div`
  width: calc(35% - 35px);
  float: left;
  position: absolute;
  right: 0;
  @media (max-width: 767px) {
    position: static;
    width: 100%;
    margin-top: 20px;
  }
`;

const WellnessExpertImage = styled.img`
  width: 100%;
  padding: 22px 5px 19px;
`;

const FormContainer = styled.div`
  width: 65%;
  margin-right: 35px;
  display: inline-block;
  float: left;
  -webkit-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  float: left;
  width: 100%;
  position: relative;
`;

export { Layout, PageTitle, ContentContainer, WellnessExpertImage, FormContainer, Wrapper };
