import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Layout, PageTitle, ContentContainer, FormContainer, Wrapper } from './styles';

import { SearchAmigoForm, AmigoList, WellnessExpertSection, Waiting } from '../../components';

import { getWellnessExpert, clearSearchAmigoList} from '../../redux/actions';
import {LOGO_URL} from '../../utils/config';

class SearchAmigo extends Component {

  constructor() {
    super();
    this.state = {
      checked: true
    }
  }

  componentDidMount() {
    if(!this.props.wellnessExpert) {
      this.props.fetchWellnessExpert();
    }
    const {clearSearchAmigoList} = this.props;
    clearSearchAmigoList([]);
  }

  checkFunction = () => {
    this.setState((prev) => ({
      checked: !prev.checked
    }))
  };

  render() {
    const { amigoList, wellnessExpert, totalAmigoCount,isLoading } = this.props;
    if(_.isNull(wellnessExpert)) {
      return <Waiting />
    }
    return (
      <Layout>
        <PageTitle>{`find ${LOGO_URL ? '' : 'training'} buddies`}</PageTitle>
        <Wrapper>
          <FormContainer>
            <SearchAmigoForm checkFunction={this.checkFunction} checked={this.state.checked} />
          </FormContainer>
          {(!_.isUndefined(amigoList) && !_.isNull(amigoList)) && <AmigoList list={amigoList} companyId={this.state.checked} totalAmigoCount={totalAmigoCount} isLoading={isLoading}/>}
          <ContentContainer>
            <WellnessExpertSection data={wellnessExpert} />
          </ContentContainer>
        </Wrapper>
      </Layout>
    )
  }
}

SearchAmigo.propTypes = {
  amigoList: PropTypes.array,
  fetchWellnessExpert: PropTypes.func.isRequired,
  clearSearchAmigoList:PropTypes.func.isRequired,
  wellnessExpert: PropTypes.object,
  isLoading: PropTypes.bool,
  totalAmigoCount: PropTypes.number
};

const mapStateToProps = (state) => ({
  amigoList: state.profileData.amigoList,
  wellnessExpert: state.social.wellnessExpert,
  isLoading: state.profileData.isLoading,
  totalAmigoCount: state.profileData.totalAmigoCount
});

const mapDispatchToProps = (dispatch) => ({
  fetchWellnessExpert: () => dispatch(getWellnessExpert()),
  clearSearchAmigoList: () => dispatch(clearSearchAmigoList())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchAmigo);
